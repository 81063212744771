var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selected)?_c('UserProfile',{attrs:{"show-head":false,"propped-user":_vm.user,"show-campuses":false,"show-roles":false,"show-campus":false,"guardian-mode":true,"tabs":[
    { title: 'User Details', role: 'details' },
    { title: 'Profile', role: 'profile' } ],"profile-fields":[
    'id',
    'gender',
    'ethnicity',
    'home_address',
    'work_address',
    'occupation'
  ],"type":'Guardian',"canCreateEnrolment":!!_vm.$store.getters['entities/user-permissions/find']('create enrolments'),"canDeleteEnrolment":!!_vm.$store.getters['entities/user-permissions/find']('delete enrolments'),"canEditEnrolment":!!_vm.$store.getters['entities/user-permissions/find']('edit enrolments'),"canCreateSubject":!!_vm.$store.getters['entities/user-permissions/find']('create subjects'),"canDeleteSubject":!!_vm.$store.getters['entities/user-permissions/find']('delete subjects'),"canEditSubject":!!_vm.$store.getters['entities/user-permissions/find']('edit subjects'),"canCreateExtramural":!!_vm.$store.getters['entities/user-permissions/find']('create extramurals'),"canDeleteExtramural":!!_vm.$store.getters['entities/user-permissions/find']('delete extramurals'),"canEditExtramural":!!_vm.$store.getters['entities/user-permissions/find']('edit extramurals'),"canCreate":!!_vm.$store.getters['entities/user-permissions/find']('create guardians'),"canDelete":!!_vm.$store.getters['entities/user-permissions/find']('delete guardians'),"canEdit":true,"canCreateGuardian":!!_vm.$store.getters['entities/user-permissions/find']('edit learners'),"canDeleteGuardian":!!_vm.$store.getters['entities/user-permissions/find']('edit learners'),"canEditGuardian":!!_vm.$store.getters['entities/user-permissions/find']('edit learners'),"canCreateWard":true,"canDeleteWard":!!_vm.$store.getters['entities/user-permissions/find']('edit guardians'),"canEditWard":true,"can-create-learner":true},on:{"deleted":_vm.handleDelete}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }